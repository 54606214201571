<template>
  <v-container
    fluid
    grid-list-xl
    class="bonds"
  >
    <h1>{{ $t('bonds.activations') }}</h1>
    <v-row
      align="center"
      class="mt-3"
    >
      <v-col
        cols="6"
        xs="2"
        md="2"
      >
        <v-select
          v-model="filter.month"
          :items="filterValues.months"
          :label="$t('bonds.month')"
          item-text="label"
          item-value="id"
          hide-details
          class="mt-0"
        ></v-select>
      </v-col>
      <v-col
        cols="2"
        xs="2"
        md="1"
      >
        <v-select
          v-model="filter.year"
          :items="filterValues.years"
          :label="$t('bonds.year')"
          hide-details
          class="mt-0"
        ></v-select>
      </v-col>
      <v-col>
        <v-btn
          color="primary"
          @click="filterBonds()"
        >
          <v-icon left>
            search
          </v-icon>
          {{ $t('bonds.filter') }}
        </v-btn>
      
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :items="bonds"
          :headers="tableHeaders"
          multi-sort
          :items-per-page="20"
          :footer-props="{
            itemsPerPageOptions: [20,40,100]
          }"
          class="user-table"
          :loading="loading"
        >
          <template v-slot:item.issueDate="{ item }">
            {{ new Date(item.issueDate).toLocaleDateString() }}
          </template>
          <template v-slot:item.tailorMade="{ item }">
            {{ item.tailorMade ? $t('yes') : $t('no') }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import IdField from "@/components/common/IdField"
  import {mapActions} from "vuex"

  export default {
    name: "BondsActivations",
    components: {IdField},
    data() {
      var months = [];
      for (let step = 1; step < 13; step++) {
        months.push({
          id: step,
          label: this.$t('months.' + (new Date('2022-'+String(step).padStart(2, '0')+'-01').toLocaleString('en-us', {month: 'long'})).toLowerCase()),
        })
      }
      var years = [], i = 0, currentDate = new Date()
      var currentMonth = currentDate.getMonth()
      var currentYear = currentDate.getFullYear()
      while(i<3) {
        years.push(currentYear-i)
        i++
      }
      return {
        loading: false,
        bonds: [],
        filter: {
          month: currentMonth,
          year: currentYear,
        },
        filterValues: {
          months: months,
          years: years,
        },
      }
    },
    computed: {
      tableHeaders() {
        return [
          {
            text: this.$t('bonds.isin'),
            value: 'isin',
          },
          {
            text: this.$t('bonds.issuer'),
            value: 'issuerName',
          },
          {
            text: this.$t('bonds.name'),
            value: 'name',
          },
          {
            text: this.$t('bonds.issueDate'),
            value: 'issueDate',
          },
          {
            text: this.$t('bonds.intermediary'),
            value: 'intermediaryName',
          },
          {
            text: this.$t('bonds.tailorMade'),
            value: 'tailorMade',
          },
        ]

      },
    },
    mounted() {
      this.filterBonds()
    }
    ,
    methods: {
      ...mapActions(['filterActivatedBonds']),
      async filterBonds() {
        this.loading = true

        try {
          const response = await this.filterActivatedBonds({
            issueDayMonth: this.filter.month, 
            issueDayYear: this.filter.year,
          })
          if (response) {
            this.bonds = response.data,
            this.loading = false
          }
        } catch {
          this.showSnackbar({
            text: this.$t('bonds.searchError'),
            type: 'error',
          })
          this.loading = false
        }
      },
    },
  }
</script>

<style lang="scss">
.button-column {
  width: 1px;
  min-width: 1px;
}

div.jsoneditor-menu {
  background-color: initial;
  border-bottom: none;
}

.users {
  .user-table {
    th {
      //font-size: 0.875rem !important
    }
  }
}
</style>
